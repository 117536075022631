import { makeStyles, createStyles, lighten, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      overflow: 'hidden',
    },
    table: {
      minWidth: 750,
    },
    primaryHead: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    primaryDarkHead: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    secondaryDarkHead: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
    },
    grey800Head: {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    avatar: {
      width: 120,
      height: 120,
      margin: 'auto',
    },
    loading: {
      position: 'absolute',
      zIndex: 5,
      opacity: '60%',
      backgroundColor: 'whitesmoke',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabShadow: {
      marginBottom: '4px',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    },
  })
})

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
)
