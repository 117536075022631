import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../../../..'
import {
  createVendorProductDealPrice,
  CreateVendorProductDealPriceRequest,
  IVendor,
} from '../../../../../../api/smartbestbuys-api'
import { useVendors } from '../../../../../../hooks/vendor/useVendors'

interface Props extends DialogProps {
  productId: number
  onComplete?: () => void
  onFail?: () => void
}

const useStyles = makeStyles({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  avatar: {
    width: 32,
    height: 32,
  },
})

const ProductDealCreateModal: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const vendors = useVendors()
  const { onClose, onComplete, onFail, ...dialogProps } = props

  const [selectedVendor, setSelectedVendor] = useState<IVendor | undefined>(undefined)
  const [price, setPrice] = useState<number | undefined>(undefined)
  const [remark, setRemark] = useState<string | undefined>(undefined)
  const [priceError, setPriceError] = useState<string>('')

  const handleVendorChange = (event: any, value: any) => {
    setSelectedVendor(value || undefined)
  }

  const { triggerSnackbar } = useContext(RootContext)

  const handleSubmit = async () => {
    if (!selectedVendor) {
      triggerSnackbar('กรุณาเลือกผู้ขายก่อนส่งข้อมูล')
      onFail && onFail()
      return
    }

    if (price === undefined || price <= 0) {
      setPriceError('กรุณาใส่ราคาที่ถูกต้อง')
      return
    } else {
      setPriceError('')
    }

    const request: CreateVendorProductDealPriceRequest = {
      price: price,
      remark,
    }

    try {
      const response = await createVendorProductDealPrice(props.productId, selectedVendor.id, request)
      if (response?.status === 201) {
        triggerSnackbar('เพิ่มข้อมูลดีลสำเร็จ')
        onComplete && onComplete()
        props.onClose?.({}, 'escapeKeyDown')
      } else {
        triggerSnackbar('เพิ่มข้อมูลดีลไม่สำเร็จ')
        onFail && onFail()
      }
    } catch (error) {
      triggerSnackbar('เกิดข้อผิดพลาดในการเพิ่มข้อมูลดีล')
      onFail && onFail()
    }
  }

  useEffect(() => {
    setSelectedVendor(undefined)
    setPrice(undefined)
    setPriceError('')
  }, [props.open])

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <DialogTitle>สร้างดีล</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2 w-full">
                  <span>เลือกผู้ขาย</span>
                  <Autocomplete
                    options={vendors.map((vendor) => {
                      return {
                        ...vendor,
                        searchVendor: `(${vendor.code}) ${vendor.name}`,
                      }
                    })}
                    getOptionLabel={(option) => option.searchVendor}
                    onChange={handleVendorChange}
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="ค้นหาผู้ขาย" />}
                    clearOnEscape
                    renderOption={(option) => (
                      <div className={classes.optionContainer}>
                        <Avatar src={option.profileImageUrl} alt={option.name} className={classes.avatar} />
                        <span>
                          ({option.code}) {option.name}
                        </span>
                      </div>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <span>ราคา ({selectedVendor?.currency?.name})</span>
                  <TextField
                    variant="outlined"
                    value={price}
                    onChange={(e) => {
                      const value = e.target.value
                      setPrice(value === '' ? undefined : Number(value))
                    }}
                    type="number"
                    error={!!priceError}
                    helperText={priceError}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <span>หมายเหตุ</span>

                  <TextField
                    variant="outlined"
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value)
                    }}
                    type="text"
                    multiline={true}
                    rows={3}
                  />
                </div>
              </div>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div className="flex justify-end gap-2">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!selectedVendor || price === undefined}
              >
                ยืนยัน
              </Button>
              <Button variant="contained" color="inherit" onClick={() => props.onClose?.({}, 'escapeKeyDown')}>
                ยกเลิก
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ProductDealCreateModal
