import { StyleSheet } from '@react-pdf/renderer'

export const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

export const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 37,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
  },
  imageProductGallery: {
    height: 150,
    width: 'auto',
    paddingRight: 20,
  },
})
