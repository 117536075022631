import { DialogProps, MenuItem, TextField, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { useContext, useMemo, useState } from 'react'
import { RootContext } from '../../..'
import {
  confirmTransportSubTaskAPI,
  finishTransportSubTaskAPI,
  FinishTransportSubTaskRequestBody_RequestChangeItem,
  TransportDocumentPreparingTask,
  TransportTaskStateV2_Item,
} from '../../../api/smartbestbuys-api'
import { noteTaskAsCancelAPI } from '../../../api/smartbestbuys-api/tasks/task-apis'
import { Table, TColumn } from '../../../custom-components'
import { DocumentStatus, TaskStatus, DocumentType, DocumentPrefix } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { TransportTaskItemType } from '../../../enums/TransportTaskEnums'
import { useSaleOrderDocuments } from '../../../hooks'
import TransportSubTaskDialog, {
  ITransportSubTaskDialogData,
  ITransportSubTaskDialogOptions,
} from './TransportSubTaskDialog'

interface Props {
  data: TransportDocumentPreparingTask
  onSuccess?: (data: TransportDocumentPreparingTask) => void
  onFail?: () => void
}

const PrepareDocumentTaskDialog: React.FC<DialogProps & Props> = (props) => {
  const { triggerSnackbar } = useContext(RootContext)

  const { data, onSuccess, onFail } = props

  // state
  const [updateItemList, setUpdateItemList] = useState<FinishTransportSubTaskRequestBody_RequestChangeItem[]>([])
  const [submitting, setSubmitting] = useState(false)

  const saleOrderId = data.parent.saleOrderInfo?.id

  const saleOrderDocuments = useSaleOrderDocuments(saleOrderId)

  const subTaskData: ITransportSubTaskDialogData = {
    id: data.id,
    code: data.code,
    status: {
      id: data.status.id,
      name: data.status.name,
    },
    type: {
      id: data.type.id,
      name: data.type.name,
    },
    saleOrderId: data.parent.saleOrderInfo?.id,
    saleOrderCode: data.parent.saleOrderInfo?.code,
    customerName: data.parent.saleOrderInfo?.customerName,
    salesName: data.parent.saleOrderInfo?.createdBy,
    dueDate: data.parent.dueDate ? DateTime.fromJSDate(new Date(data.parent.dueDate)) : undefined,
    useShippingProdiver: data.parent.state?.shippingInfo?.useShippingProvider,
    providerName: data.parent.state?.shippingInfo?.shippingProvider?.name,
    messenger: data.parent.state?.shippingInfo?.messengerInfo?.name,
    car: data.parent.state?.shippingInfo?.messengerInfo?.car?.name,
    remark: data.parent.state?.shippingInfo?.remark,
    relatedPO: data.parent.relatedPO,
  }
  const documents = useMemo(() => {
    return data.parent.state?.items.filter((item) => item.type === TransportTaskItemType.Document) ?? []
    // eslint-disable-next-line
  }, [data.id])

  const updatedAll = useMemo(() => {
    return documents.filter((d) => !!d.requestChange).length === updateItemList.length
    // eslint-disable-next-line
  }, [updateItemList.length])

  const pendingSaleOrderDocuments = useMemo(() => {
    return saleOrderDocuments.filter((d) => d.status.id === DocumentStatus.Pending)
    // eslint-disable-next-line
  }, [saleOrderDocuments.length])

  const submitText = useMemo(() => {
    switch (data.status.id) {
      case TaskStatus.Pending:
        return 'ยืนยันรับงาน'
      case TaskStatus.Processing:
        return updatedAll ? 'ยืนยันเตรียมเอกสารเสร็จสิ้น' : 'เลือกเอกสารให้ครบ'
      case TaskStatus.PreCancel:
        return 'รับทราบการยกเลิกงาน'
      default:
        return undefined
    }
  }, [data.status.id, updatedAll])

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      switch (data.status.id) {
        case TaskStatus.Pending:
          const confirmResponse = await confirmTransportSubTaskAPI({ taskId: data.parent.id, subTaskId: data.id })
          if (confirmResponse?.status === 200) {
            triggerSnackbar(`รับงานเลขที่ ${data.code} สำเร็จ`)
            if (onSuccess) onSuccess(confirmResponse.data)
          } else {
            triggerSnackbar(`รับงานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
          }
          setSubmitting(false)
          break
        case TaskStatus.Processing:
          const finishResponse = await finishTransportSubTaskAPI(
            { taskId: data.parent.id, subTaskId: data.id },
            { updateItemList },
          )
          if (finishResponse?.status === 200) {
            triggerSnackbar(`เตรียมเอกสารงานเลขที่ ${data.code} สำเร็จ`)
            if (onSuccess) onSuccess(finishResponse.data)
          } else {
            triggerSnackbar(`เตรียมเอกสารงานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
          }
          setSubmitting(false)
          break
        case TaskStatus.PreCancel:
          const notedResponse = await noteTaskAsCancelAPI(data.id)
          if (notedResponse?.status === 200) {
            triggerSnackbar(`ยกเลิกงานเลขที่ ${data.code} สำเร็จ`)
            // NOTE: note as cancel task is not return like subtask
            if (onSuccess) onSuccess(notedResponse.data as TransportDocumentPreparingTask)
          } else {
            triggerSnackbar(`ยกเลิกงานเลขที่ ${data.code} ไม่สำเร็จ`)
            if (onFail) onFail()
          }
          setSubmitting(false)
          break
        default:
          if (onSuccess) onSuccess(data)
          setSubmitting(false)
          break
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isProcessing = data.status.id === TaskStatus.Processing

  const options: ITransportSubTaskDialogOptions = {
    submitFormProps: {
      confirmTextOption: isProcessing
        ? {
            veifiedText: 'ยืนยัน',
          }
        : undefined,
      hideCancelButton: true,
      submitText,
      disableSubmit: isProcessing && !updatedAll,
      submitPermission: PermissionEnum.Finance_Document_Write,
      onSubmit: handleSubmit,
      isSubmitting: submitting,
    },
  }

  const handleNewDocumentSelect = (itemId: number, documentId: number) => {
    setUpdateItemList((prev) => {
      let cloned = [...prev]
      const index = cloned.findIndex((l) => l.itemId === itemId)
      const item = { itemId, documentId, code: pendingSaleOrderDocuments.find((d) => d.id === documentId)!.code }
      if (index === -1) {
        return [...cloned, item]
      } else {
        cloned[index] = item
        const isTaxInvoice = -itemId === DocumentType.TaxInvoice
        if (isTaxInvoice) {
          return cloned.filter((d) => ![DocumentType.BillingNote, DocumentType.Receipts].includes(-d.itemId))
        }
        return [...cloned]
      }
    })
  }

  const columns = [
    { name: 'ลำดับ', render: (_, index) => index + 1, fixedWidth: 70 },
    {
      name: 'ชื่อเอกสาร',
      render: ({ code, name }) => (
        <Typography variant="body2">
          {name} {code}
        </Typography>
      ),
    },
    {
      name: 'อัปเดตเลขที่เอกสาร',
      fixedWidth: 200,
      hide: !isProcessing,
      render: ({ id, name, code }) => {
        let targetDocuments = pendingSaleOrderDocuments.filter((s) => s.type.id === -id)

        // if target document are in billing note or receipt
        const isCustomerDocument = [DocumentType.BillingNote, DocumentType.Receipts].includes(-id)
        if (isCustomerDocument) {
          const selectedTaxInvoiceIds = [
            ...updateItemList
              .filter((item) => item.code.includes(DocumentPrefix.TaxInvoice))
              .map((item) => item.documentId),
            ...documents.filter((d) => d.code?.includes(DocumentPrefix.TaxInvoice)).map((d) => d.id),
          ]

          if (selectedTaxInvoiceIds.length === 0)
            return (
              <Typography variant="body2" color="textSecondary">
                กรุณาเลือก "ใบกำกับภาษี"
              </Typography>
            )

          targetDocuments = targetDocuments.filter((d) =>
            d.state?.items?.some((item: any) => selectedTaxInvoiceIds.includes(item.id)),
          )
        }

        if (targetDocuments.length > 0) {
          return (
            <TextField
              variant="outlined"
              onChange={(e) => handleNewDocumentSelect(id, Number(e.target.value))}
              select
              size="small"
              fullWidth
            >
              {targetDocuments.map((d) => {
                return (
                  <MenuItem key={d.id} value={d.id}>
                    {d.code}
                  </MenuItem>
                )
              })}
            </TextField>
          )
        } else if (!code) {
          return (
            <Typography variant="body2" color="textSecondary">
              ไม่มี "{name}" จากใบสั่งขาย
            </Typography>
          )
        }
      },
    },
    {
      name: 'จำนวนที่จะส่ง',
      fixedWidth: 200,
      dataIndex: 'amount',
      numeric: true,
      format: '0,0',
    },
  ] as TColumn<TransportTaskStateV2_Item>[]

  return (
    <TransportSubTaskDialog {...props} data={subTaskData} options={options}>
      <Typography variant="subtitle2">รายละเอียดเอกสารที่ต้องเตรียม</Typography>
      <Table<TransportTaskStateV2_Item>
        color="primary.dark"
        size="small"
        cleanSpace
        pagination={false}
        columns={columns.filter((c) => !c.hide)}
        data={documents}
      />
    </TransportSubTaskDialog>
  )
}

export default PrepareDocumentTaskDialog
