export const customerBusinessTypes = [
  // { id: 1, name: 'บริษัท/หจก/หสม', detail: '' }, // doesn't use
  { id: 2, name: 'ราชการ', detail: 'หน่วยงานของรัฐ, สถานที่สาธารณะ, อบต, อบจ' },
  // { id: 3, name: 'บุคคลธรรมดา', detail: '' }, // doesn't use
  {
    id: 4,
    name: 'ที่อยู่อาศัย',
    detail: 'คอนโดมิเนียม, หมู่บ้านจัดสรร, นิติบุคคลอาคารชุด, สำนักงานอาคาร, อพาร์ทเมนท์',
  },
  {
    id: 5,
    name: 'อุตสาหกรรมและการผลิต',
    detail: 'โรงงานผลิต, โกดังสินค้า',
  },
  {
    id: 6,
    name: 'อาคารสำนักงาน',
    detail: 'ศูนย์การค้า, ร้านค้าปลีก, ตลาด, สถานฑูต, สนามบิน',
  },
  {
    id: 7,
    name: 'ภาคบริการที่พัก',
    detail: 'โรงแรม, บังกะโล, รีสอร์ท, หอพัก, สนามกอล์ฟ',
  },
  {
    id: 8,
    name: 'สถาบันการศึกษา',
    detail: 'โรงเรียน, มหาวิทยาลัย',
  },
  {
    id: 9,
    name: 'ซื้อไปติดตั้งให้ลูกค้า',
    detail: 'ผู้รับเหมา, ซื้อไปติดตั้งให้ลูกค้า, สถาปนิก, วิศวกร',
  },
  {
    id: 10,
    name: 'ร้านค้า',
    detail: 'ร้านอาหาร, ร้านกาแฟ, ร้านซักรีด, สตูดิโอ',
  },
  {
    id: 11,
    name: 'ตัวแทนจำหน่าย',
    detail: 'ซื้อไปขายต่อแต่ไม่ได้ติดตั้งให้, การฝากขาย, Modern trade',
  },
  {
    id: 12,
    name: 'ผู้บริโภครายบุคคล',
    detail: 'ใช้งานภายในบ้านหรือส่วนตัว',
  },
  {
    id: 13,
    name: 'โรงพยาบาลเอกชน',
    detail: 'โรงพยาบาลเอกชน',
  },
  {
    id: 14,
    name: 'นายหน้า',
    detail: 'เอเจนท์, Affiliate, Influencer',
  },
  {
    id: 15,
    name: 'Service Center',
    detail: '',
  },
  {
    id: 16,
    name: 'Market Place',
    detail: 'Shoppee, Lazada, Officemate, Tiktok',
  },
]
