import { Avatar, Button, Grid, Paper, Typography } from '@material-ui/core'
import { useContext, useState } from 'react'
import { IProductVendorDealPriceHistories } from '../../../../../../api/smartbestbuys-api'
import { Table } from '../../../../../../custom-components'
import { dateFormat } from '../../../../../../functions'
import ProductProfileContext from '../../ProductProfileContext'
import ProductDealCreateModal from './ProductDealCreateModal'

const ProductOtherInfo: React.FC = () => {
  const { productProfile, reload } = useContext(ProductProfileContext)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Paper>
      {isModalOpen && (
        <ProductDealCreateModal
          open={isModalOpen}
          onClose={handleCloseModal}
          productId={productProfile?.id}
          onComplete={reload}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="flex justify-between items-center">
            <Typography variant="body1" color="textSecondary">
              ราคาสินค้าที่ตกลงกับผู้ขาย
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenModal}>
              สร้างดีล
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table<IProductVendorDealPriceHistories>
            color="primary.dark"
            columns={[
              {
                name: 'ข้อมูลผู้ขาย',
                render: ({ vendor }) => (
                  <div className="flex items-center gap-2">
                    <Avatar src={vendor.profileImageUrl} />
                    <div className="flex flex-col">
                      <Typography variant="body1" color="primary">
                        {vendor.code}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {vendor.name}
                      </Typography>
                    </div>
                  </div>
                ),
              },
              {
                name: 'เบอร์โทร',
                render: ({ vendor }) => <div>{vendor.phoneNumber}</div>,
              },
              {
                name: 'อีเมล',
                render: ({ vendor }) => <div>{vendor.email}</div>,
              },
              {
                name: 'ราคา',
                dataIndex: 'price',
                numeric: true,
                format: '0,0.00',
              },
              {
                name: 'สกุลเงิน',
                render: ({ vendor }) => <div>{vendor.currency.name}</div>,
              },
              {
                name: 'หมายเหตุ',
                render: ({ remark }) => <div>{remark ?? '-'}</div>,
                fixedWidth: 250,
              },
              {
                name: 'วันที่สร้าง',
                dataIndex: 'recCreatedAt',
                render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                fixedWidth: 150,
              },
              {
                name: 'สร้างโดย',
                dataIndex: 'recCreatedBy',
                render: ({ recCreatedBy }) => <div>{recCreatedBy}</div>,
                fixedWidth: 150,
              },
            ]}
            data={productProfile?.vendorDealPriceHistories?.sort((a, b) => b.id - a.id) ?? []}
            size="small"
            cleanSpace
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductOtherInfo
