import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { useContext, useState, useEffect, useMemo } from 'react'
import { RootContext } from '../../../..'
import { CheckboxForm, Form, GeneralTextFieldForm, KeyboardDatePickerForm } from '../../../../custom-components'
import { useForm } from 'react-hook-form'
import SaleOrderPartialUpdateDialogFromValues from './SaleOrderPartialUpdateDialogFromValues'
import { WarningDialog } from '../../../../sales-system/components'
import { customerPickUpMethods, paymentConditions } from '../../../../types'
import { ButtonRadios } from '../../../../shared-components'
import { partialUpdateSaleOrder } from '../../../../api/smartbestbuys-api/sale/sale-apis'
import { mapPartialUpdateFromValuesToSaleOrderPartialUpdateRequest } from './mapper'
import { ICustomerProfile, SaleOrderProfile } from '../../../../api/smartbestbuys-api'
import { PaymentCondition, SaleOrderStatus } from '../../../../enums'
import { getUser } from '../../../../functions'

interface Props extends DialogProps {
  saleOrderProfile: SaleOrderProfile
  customerPORefs?: string[]
  onComplete?: () => void
  customerProfile?: ICustomerProfile
}

const SaleOrderPartialUpdateDialog: React.FC<Props> = (props: Props) => {
  const { customerPORefs, saleOrderProfile, onComplete, customerProfile, onClose, ...otherProps } = props
  const [duplicatedAlert, setDuplicatedAlert] = useState(false)
  const { triggerSnackbar } = useContext(RootContext)

  const isInProgress = ![SaleOrderStatus.Closed, SaleOrderStatus.ClosedAtShop].includes(saleOrderProfile.status.id)

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
    setValue,
    register,
  } = useForm<SaleOrderPartialUpdateDialogFromValues>({
    defaultValues: {
      customerPurchaseReference: saleOrderProfile.customerPurchaseReference,
      dontHasCustomerPurchaseReference: !saleOrderProfile.customerPurchaseReference,
      customerPickUpMethod: saleOrderProfile.customerPickUpMethod?.id,
      paymentConditionId: saleOrderProfile.paymentCondition.id,
      dueDate: saleOrderProfile.dueDate,
      assigneeUserId: saleOrderProfile.assigneeUser?.id,
    },
  })

  const { customerPurchaseReference, dontHasCustomerPurchaseReference, customerPickUpMethod, dueDate } = watch()

  const actualPaymentConditions = useMemo(() => {
    let _paymentConditions = paymentConditions

    if (customerProfile?.isBlacklist) {
      _paymentConditions = paymentConditions.filter((pc) => pc.id === PaymentCondition.PaymentRequire)
    } else if (!customerProfile?.creditPeriodDay) {
      _paymentConditions = paymentConditions.filter((pc) => pc.id !== PaymentCondition.Credit)
    } else {
      _paymentConditions = paymentConditions
    }

    return _paymentConditions
  }, [customerProfile?.creditPeriodDay, customerProfile?.isBlacklist])

  const activeMethod = customerPickUpMethods.find((cpm) => cpm.id === customerPickUpMethod)

  const handleSubmitSaleOrderPartialUpdateForm = async (value: SaleOrderPartialUpdateDialogFromValues) => {
    const request = mapPartialUpdateFromValuesToSaleOrderPartialUpdateRequest(value)

    const response = await partialUpdateSaleOrder(saleOrderProfile.id, request)
    if (response?.status === 200) {
      triggerSnackbar('บันทึกการแก้ไขใบสั่งขายสำเร็จ')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('บันทึกการแก้ไขใบสั่งขายไม่สำเร็จ')
      if (!!onClose) onClose({}, 'escapeKeyDown')
    }
  }

  useEffect(() => {
    register('dueDate', {
      required: {
        value: true,
        message: 'กรุณาเลือกวันที่ลูกค้ารับของ',
      },
      valueAsDate: true,
    })
  }, [register])

  const isBanned = useMemo(() => {
    const user = getUser()
    const username = user?.username
    const bannedUsernameList = ['servicemanager', 'service04', 'service18', 'service25', 'minapapol']
    return bannedUsernameList.includes(username ?? '')
  }, [])

  if (!saleOrderProfile) return <></>
  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <Form onSubmit={handleSubmit(handleSubmitSaleOrderPartialUpdateForm)}>
        <DialogTitle>แก้ไขข้อมูลเพิ่มเติมของใบสั่งขาย</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GeneralTextFieldForm
                fullWidth
                control={control}
                name="customerPurchaseReference"
                label="เลขที่การสั่งซื้อของลูกค้า"
                disabled={dontHasCustomerPurchaseReference}
                onBlur={(e) => {
                  if (!e.target.value) return
                  const lowerCase = e.target.value.toLowerCase()
                  const lowerCasePOs = customerPORefs
                    ?.filter((po) => !!po && po !== 'ไม่มี PO')
                    .map((po) => po.toLowerCase())
                  setDuplicatedAlert(!!lowerCasePOs && lowerCasePOs.includes(lowerCase))
                }}
              />
              <FormControlLabel
                control={<CheckboxForm size="small" control={control} name="dontHasCustomerPurchaseReference" />}
                label={<Typography variant="body2">ไม่มีเลขที่การสั่งซื้อของลูกค้า</Typography>}
              />
              <WarningDialog
                title="เลขที่การสั่งซื้อของลูกค้านี้เคยถูกสร้างใบสั่งขายแล้ว"
                open={duplicatedAlert}
                onOk={() => setDuplicatedAlert(false)}
                onClose={() => setDuplicatedAlert(false)}
              >
                เลขที่การสั่งซื้อของลูกค้าเลขที่ {customerPurchaseReference} เคยถูกสร้างใบสั่งขายแล้ว
                กรุณาตรวจสอบให้แน่ใจว่าต้องการสร้างใบสั่งขายนี้ต่อ
              </WarningDialog>
            </Grid>
            {isInProgress && (
              <>
                {!isBanned && (
                  <Grid item xs={12} md={6}>
                    <ButtonRadios
                      label="เงื่อนไขการชำระเงิน *"
                      onChange={(paymentConditionId) => setValue('paymentConditionId', paymentConditionId)}
                      items={actualPaymentConditions}
                      defaultValue={saleOrderProfile?.paymentCondition.id}
                    />
                    {errors.paymentConditionId && (
                      <Box>
                        <Typography variant="caption" color="error" gutterBottom>
                          {errors.paymentConditionId?.message}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                )}
                {!isBanned && (
                  <Grid item xs={12} md={6}>
                    <Box>
                      <ButtonRadios
                        label="วิธีการรับสินค้า *"
                        defaultValue={saleOrderProfile?.customerPickUpMethod?.id}
                        items={customerPickUpMethods}
                        onChange={(id) => setValue('customerPickUpMethod', id)}
                      />
                      {activeMethod?.warningMessage && (
                        <Box color="warning.dark" mt={0.5}>
                          <Typography variant="body2">{activeMethod.warningMessage}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <KeyboardDatePickerForm
                    control={control}
                    label={'วันที่ลูกค้าต้องการ *'}
                    name="dueDate"
                    value={dueDate ? new Date(dueDate) : null}
                    defaultValue={null}
                    // inputVariant="outlined"
                    size="small"
                    fullWidth
                    error={!!errors.dueDate}
                    helperText={errors.dueDate?.message}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ยกเลิก
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            บันทึกใบสั่งขาย
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default SaleOrderPartialUpdateDialog
