import { Button, Grid, Paper } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { FlexBox, SubTopic, CustomPaper } from '../../../../../../custom-components'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { ConditionTooltip } from '../../../../../../shared-components'
import { getUserPermissionInfo } from '../../../../../../utils/permission'
import CustomerProfileContext from '../../CustomerProfileContext'
import { CustomerContactCard } from './CustomerContactCard'
import { CustomerContactCreationDialog } from './CustomerContactDialog'

const CustomerContactList: React.FC = () => {
  const [createCustomerContact, setCreateCustomerContact] = useState(false)

  const {
    customerProfile: { id: customerId, contacts, recDeletedAt },
  } = useContext(CustomerProfileContext)

  const writePermission = getUserPermissionInfo(PermissionEnum.Sales_Customer_Write)

  const isActiveCustomer = !recDeletedAt

  const disableWrite = !writePermission.hasPermission || !isActiveCustomer

  return (
    <Paper>
      <CustomerContactCreationDialog
        open={createCustomerContact}
        onClose={() => {
          setCreateCustomerContact(false)
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SubTopic>ผู้ติดต่อ</SubTopic>
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexBox justifyContent="flex-end">
            <ConditionTooltip
              title={
                !writePermission.hasPermission ? writePermission.alertMessage : 'ลูกค้าคนนี้ถูกยกเลิกการใช้งานแล้ว'
              }
              showTooltip={disableWrite}
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={disableWrite}
                onClick={() => {
                  setCreateCustomerContact(true)
                }}
              >
                + เพิ่มผู้ติดต่อ
              </Button>
            </ConditionTooltip>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <CustomPaper isEmpty={!contacts || contacts.length === 0} emptyText="ยังไม่มีผู้ติดต่อ">
            <Grid container spacing={2}>
              {contacts?.map((contact) => (
                <Grid item xs={12} key={contact.id}>
                  <CustomerContactCard customerId={customerId} contact={contact} isActiveCustomer={!recDeletedAt} />
                </Grid>
              ))}
            </Grid>
          </CustomPaper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerContactList
