import { Box, Paper } from '@material-ui/core'
import React from 'react'
import { IQuotationTicket } from '../../../../api/smartbestbuys-api/interfaces'
import { SubTopic } from '../../../../custom-components/Typography'

interface QuotationTicketProps {
  ticket: IQuotationTicket
}

const QuotationTicket: React.FC<QuotationTicketProps> = ({ ticket }) => {
  return (
    <Box>
      <Paper className="flex flex-col gap-2">
        <SubTopic>รายละเอียดคำขอใบเสนอราคา</SubTopic>
        <div className="rounded-md border-2 bg-neutral-50 p-4">
          <div>รายละเอียด: {ticket.content}</div>
        </div>
      </Paper>
    </Box>
  )
}

export default QuotationTicket
