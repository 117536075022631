import { useTableDataV2 } from '../useTablaData'
import {
  GetWarehousePrepareProductTaskQueryParams,
  WarehousePrepareProductTaskTableData,
} from '../../api/smartbestbuys-api/tasks/task-types'
import { getWarehousePrepareProductTaskAPI } from '../../api/smartbestbuys-api/tasks/task-apis'
import { TaskStatus } from '../../enums'

const _buildQueryParams = (
  _s: WarehousePrepareProductTaskTableData.ISearchParams,
): GetWarehousePrepareProductTaskQueryParams => {
  return {
    statusId: _s.statusId ? _s.statusId.toString() : undefined,
    saleOrderCode: _s.saleOrderCode,
    warehouseCode: _s.warehouseCode,
    customerName: _s.customerName,
  }
}

export const useWarehousePrepareProductTaskTableData = () =>
  useTableDataV2<
    WarehousePrepareProductTaskTableData.IData,
    WarehousePrepareProductTaskTableData.ISummary,
    WarehousePrepareProductTaskTableData.ISearchParams,
    GetWarehousePrepareProductTaskQueryParams
  >(0, 10, { statusId: TaskStatus.Pending }, getWarehousePrepareProductTaskAPI, _buildQueryParams)
