import { Box, Grid, InputAdornment, MenuItem, Paper, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React, { useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { KeyboardDatePickerForm, SubTopic, TextFieldForm } from '../../../../custom-components'
import { ButtonRadios } from '../../../../shared-components'
import { contactChannels, currencies, CurrencyID, paymentMethods } from '../../../../types'
import { QuotationsCreateValue } from '../QuotationsCreateValue'
import { QuotationsCreationPageContextImpl } from '../QuotationsCreationPage'

const QuotationsMoreInformation: React.FC = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext<QuotationsCreateValue>()

  const { users } = useContext(QuotationsCreationPageContextImpl)
  const { paymentMethodId, periodDay, currencyId, dueDate } = watch()
  const periodDayHint = useMemo(() => {
    if (!!periodDay && periodDay > 0) {
      return DateTime.now().plus({ day: periodDay }).toFormat('dd LLL yyyy', { locale: 'th' })
    }
    return DateTime.now().toFormat('dd LLL yyyy', { locale: 'th' })
  }, [periodDay])

  const handleOnChangePaymentMethodId = (paymentMethodId: number) => {
    clearErrors('paymentMethodId')
    setValue('paymentMethodId', paymentMethodId)
  }

  return (
    <Box padding={2} clone>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubTopic>ข้อมูลเพิ่มเติม</SubTopic>
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonRadios
              label="วิธีการชำระเงิน *"
              onChange={(paymentMethodId) => handleOnChangePaymentMethodId(paymentMethodId)}
              items={paymentMethods}
              defaultValue={paymentMethodId}
            />
            {errors.paymentMethodId && (
              <Box>
                <Typography variant="caption" color="error" gutterBottom>
                  {errors.paymentMethodId?.message}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              select
              fullWidth
              control={control}
              name="contactChannelId"
              label="ช่องทางการติดต่อ (ถ้ามี)"
              variant="outlined"
              size="small"
              type="number"
            >
              {contactChannels.map((cc) => (
                <MenuItem key={cc.id} value={cc.id}>
                  {cc.name}
                </MenuItem>
              ))}
            </TextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              select
              fullWidth
              control={control}
              name="currencyId"
              label="สกุลเงิน *"
              variant="outlined"
              size="small"
              type="number"
              rules={{ required: { value: true, message: 'กรุณาใส่สกุลเงิน' } }}
              error={!!errors.currencyId}
              helperText={errors.currencyId?.message}
            >
              {currencies.map((c) => (
                <MenuItem key={c.name} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </TextFieldForm>
          </Grid>
          {currencyId !== CurrencyID.Baht && (
            <Grid item xs={12} md={6}>
              <TextFieldForm
                fullWidth
                control={control}
                name="exchangeRate"
                label="อัตราแลกเปลี่ยน *"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currencyId === 1 ? undefined : 'บาท'}</InputAdornment>
                  ),
                }}
                type="number"
                rules={{ required: { value: true, message: 'กรุณาใส่อัตราแลกเปลี่ยน' } }}
                error={!!errors.exchangeRate}
                helperText={errors.exchangeRate?.message}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextFieldForm
              fullWidth
              control={control}
              name="periodDay"
              label="ระยะยืนราคา *"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">วัน</InputAdornment>,
              }}
              type="number"
              rules={{ required: { value: true, message: 'กรุณาใส่ระยะยืนราคา' } }}
              error={!!errors.periodDay}
              helperText={errors.periodDay?.message || `หมดอายุยืนราคา: ${periodDayHint}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              select
              fullWidth
              control={control}
              name="assigneeUserId"
              label="ผู้เสนอราคา (ถ้ามี)"
              variant="outlined"
              size="small"
              type="number"
              defaultValue=""
            >
              {users.map((u) => (
                <MenuItem key={u.id} value={u.id}>
                  {u.firstName} {u.lastName}
                </MenuItem>
              ))}
            </TextFieldForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldForm
              fullWidth
              multiline
              rowsMax={4}
              control={control}
              name="remark"
              label="หมายเหตุ (ถ้ามี)"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePickerForm
              control={control}
              label={'วันที่ลูกค้าต้องการ'}
              name="dueDate"
              value={dueDate ? new Date(dueDate) : null}
              defaultValue={null}
              // inputVariant="outlined"
              size="small"
              fullWidth
              error={!!errors.dueDate}
              helperText={errors.dueDate?.message}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default QuotationsMoreInformation
