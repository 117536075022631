import _ from 'lodash'
import { SaleOrderProfile } from '../api/smartbestbuys-api'
import { SOPriceBreakdown } from '../sales-system/types'
import { calculateExcludingVat, calculateVatAmount } from './price-calculator'

interface PriceDetail {
  name: string
  amount: number
}

export const getSOPricebreakdown = (profile: SaleOrderProfile): PriceDetail[] => {
  const priceBreakdowns = calculatePriceBreakdownsFromSOProfile(profile)
  const { productTotal, discount, shippingFee, vatAmount, total } = priceBreakdowns

  const shippingFeeItem = shippingFee ? [{ name: 'ค่าจัดส่ง', amount: shippingFee }] : []

  return [
    { name: 'รวมเงิน', amount: productTotal },
    { name: 'ส่วนลด', amount: discount },
    ...shippingFeeItem,
    { name: 'ยอดหลังหักส่วนลด', amount: productTotal - discount },
    { name: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { name: 'ยอดเงินสุทธิ', amount: total },
  ]
}

export const calculatePriceBreakdownsFromSOProfile = (profile: SaleOrderProfile): SOPriceBreakdown => {
  const {
    products: selectedProducts,
    discountAmount,
    estimatedShippingFee: shippingFeeAmount,
    vatIncluded,
    isShippingFeeIncludingVat,
  } = profile
  const productTotal = _.chain(selectedProducts)
    .map((sp) => sp.amount * sp.price)
    .sum()
    .value()

  const discount = discountAmount ? Number(discountAmount) || 0 : 0
  let shippingFee = shippingFeeAmount ? Number(shippingFeeAmount) || 0 : 0
  if (isShippingFeeIncludingVat) {
    shippingFee = calculateExcludingVat(shippingFee)
  }

  const vatAmount = vatIncluded ? calculateVatAmount(productTotal - discount + shippingFee) : 0
  const total = productTotal - discount + shippingFee + vatAmount

  return {
    productTotal,
    discount,
    shippingFee,
    vatAmount,
    total,
  }
}
