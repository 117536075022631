import { useContext, useState } from 'react'
import { Box, Button, Grid, Paper } from '@material-ui/core'
import { GeneralTextField, Table, Topic, withLayout } from '../../../custom-components'
import { NavigationItemKey, PageName, TaskStatus } from '../../../enums'
import { dateFormat, getV3Url } from '../../../functions'
import { Loading, NotificationHead, Space, TypographyLink } from '../../../shared-components'
import { formatNumberInt } from '../../../functions/number-utils'
import { RootContext } from '../../..'
import PrepareProductTaskDialog from '../components/PrepareProductTaskDialog'
import { useWarehousePrepareProductTaskTableData } from '../../../hooks/task/useWarehousePrepareProductTaskTableData'
import {
  IWarehousePrepareProductTask,
  WarehousePrepareProductTaskTableData,
} from '../../../api/smartbestbuys-api/tasks/task-types'

const PrepareProductTaskListPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_PREPARE_TO_SHIP)

  // state
  const [selected, setSelected] = useState<IWarehousePrepareProductTask | undefined>(undefined)

  const tableData = useWarehousePrepareProductTaskTableData()

  if (!tableData) return <Loading />

  const {
    data = [],
    summary,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    refetch,
    isLoading,
  } = tableData

  const handleSubmitSearch = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={3}>
      {selected && (
        <PrepareProductTaskDialog
          data={selected}
          open={true}
          onClose={() => setSelected(undefined)}
          onSuccess={() => {
            if (!!refetch) refetch()
            setSelected(undefined)
          }}
        />
      )}
      <Topic>งานเตรียมสินค้าสำหรับขนส่ง</Topic>
      <NotificationHead navigationKey={NavigationItemKey.PrepareProductToShip} />
      <Space />
      <Paper>
        <form onSubmit={handleSubmitSearch}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="text"
                name="warehouseCode"
                onChange={(e) => tableData?.handleSearchParamChange('warehouseCode', e.target.value)}
                label="รหัสงานเตรียมสินค้า"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="text"
                name="code"
                onChange={(e) => tableData?.handleSearchParamChange('saleOrderCode', e.target.value)}
                label="รหัสใบสั่งขาย"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <GeneralTextField
                type="text"
                name="customerName"
                onChange={(e) => tableData?.handleSearchParamChange('customerName', e.target.value)}
                label="ลูกค้า"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Grid item xs={12} sm={10}></Grid>
            <Grid item xs={12} sm={2}>
              <Button
                size="medium"
                id="submitForm"
                variant="contained"
                color="primary"
                fullWidth
                className="button"
                type="submit"
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </form>
        <Table<WarehousePrepareProductTaskTableData.IData>
          columns={[
            {
              name: 'เลขที่งานเตรียมสินค้า',
              dataIndex: 'code',
              sort: true,
              fixedWidth: 170,
              render: (task) =>
                task.code.startsWith('WHx') ? (
                  <TypographyLink variant="body2" color="primary.dark" onClick={() => setSelected(task)}>
                    {task.code}
                  </TypographyLink>
                ) : (
                  <TypographyLink
                    variant="body2"
                    color="text.secondary"
                    href={getV3Url('/warehouse-tasks')}
                    target="_blank"
                  >
                    {task.code}
                  </TypographyLink>
                ),
            },
            {
              name: 'ใบสั่งขายอ้างอิง',
              fixedWidth: 170,
              render: (task) => {
                const { saleOrderInfo } = task.parent
                if (!saleOrderInfo) return 'loading...'
                return (
                  <TypographyLink
                    variant="body2"
                    color="secondary.dark"
                    href={`/sale-orders/${saleOrderInfo.id}/profile`}
                    target="_blank"
                  >
                    {saleOrderInfo.code}
                  </TypographyLink>
                )
              },
            },
            { name: 'ชื่อลูกค้า', render: (task) => task.parent.saleOrderInfo?.customerName },
            {
              name: 'วันที่สร้าง',
              fixedWidth: 150,
              dataIndex: 'dueDate',
              render: (task) => dateFormat(task.recCreatedAt),
              sort: true,
            },
            {
              name: 'วันที่ส่งสินค้า',
              fixedWidth: 150,
              dataIndex: 'dueDate',
              render: (task) => (task.dueDate ? dateFormat(task.dueDate) : 'ยังไม่ระบุ'),
              sort: true,
            },
          ]}
          data={data}
          paginationInfo={paginationInfo}
          onPageChange={handlePageChange}
          onRowPerPageChange={handleRowPerPageChange}
          isLoading={isLoading}
          sortable
          color="primary.dark"
          cleanSpace
          tabOption={{
            tabs: [
              {
                label: `งานที่ต้องเตรียม (${formatNumberInt(summary?.pendingTotal ?? 0)})`,
                value: TaskStatus.Pending,
              },
              {
                label: `งานที่กำลังเตรียม (${formatNumberInt(summary?.processingTotal ?? 0)})`,
                value: TaskStatus.Processing,
              },
              { label: 'เตรียมสินค้าเสร็จสิ้น', value: TaskStatus.Done },
              {
                label: `งานถูกยกเลิก`,
                value: TaskStatus.PreCancel,
              },
              { label: 'งานที่ยกเลิก', value: TaskStatus.Canceled },
            ],
            value: tableData?.pendingSearch.statusId,
            onChange: (_, newValue) => {
              handleSearchParamChange('statusId', Number(newValue), true)
            },
          }}
        />
      </Paper>
    </Box>
  )
}

export default withLayout(PrepareProductTaskListPage)
