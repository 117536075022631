import React, { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font, View } from '@react-pdf/renderer'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../assets/images/logo_v3.jpg'
import { usePurchaseOrderPDFInfo } from '../../../../hooks'
import { dateFormat, getAddressText } from '../../../../functions'
import { PurchaseOrderPDFInfo_Product } from '../../../../api/smartbestbuys-api'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  center: {
    textAlign: 'center',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 37,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 15,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  imageTabelCell: {
    borderRadius: 4,
    marginRight: 8,
  },
  imageProductGallery: {
    height: 150,
    width: 'auto',
    paddingRight: 20,
  },
})

const PurchaseOrderPDFPage: FC<RouteComponentProps<any>> = (props) => {
  const { id: purchaseOrderId } = props.match.params
  const purchaseOrderPDFInfo = usePurchaseOrderPDFInfo(purchaseOrderId)
  if (!purchaseOrderPDFInfo) return <Loading />

  const { paymentCondition, products, vendorInfo, customerShippingAddress } = purchaseOrderPDFInfo

  document.title = `ใบสั่งซื้อ ${purchaseOrderPDFInfo.code}`

  // calculation
  const exactDiscountAmount = purchaseOrderPDFInfo.discountAmount ?? 0
  const priceBreakdowns = [
    { label: 'รวมเงิน', value: purchaseOrderPDFInfo.productTotal },
    { label: 'ส่วนลด', value: exactDiscountAmount },
    { label: 'มูลค่าคงเหลือ', value: purchaseOrderPDFInfo.productTotal - exactDiscountAmount },
    {
      label: 'ภาษีมูลค่าเพิ่ม 7 %',
      value: purchaseOrderPDFInfo.vatIncluded
        ? purchaseOrderPDFInfo.total - purchaseOrderPDFInfo.productTotal - exactDiscountAmount
        : 0,
    },
    { label: 'ยอดเงินสุทธิ', value: purchaseOrderPDFInfo.total },
  ]

  const showedImagesPage = !!products.find((p) => !!p.imageUrl)

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-176 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                    กรุงเทพฯ 10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-114-8050, 087-022-4003, 084-544-1710
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                {purchaseOrderPDFInfo.code}
              </PDFInfo>
              <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(purchaseOrderPDFInfo.recCreatedAt)}
              </PDFInfo>
              {!!purchaseOrderPDFInfo.refSaleOrderCode && (
                <PDFInfo label="อ้างอิงใบสั่งขาย" direction="row" dataWidth={88} noMargin>
                  {purchaseOrderPDFInfo.refSaleOrderCode}
                </PDFInfo>
              )}
              <PDFInfo label="เงื่อนไขการชำระเงิน" direction="row" dataWidth={88} noMargin>
                {paymentCondition.name}
              </PDFInfo>
              <PDFInfo label="ผู้จัดทำเอกสาร" direction="row" dataWidth={88} noMargin>
                {purchaseOrderPDFInfo.recCreatedBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบสั่งซื้อ
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ชื่อผู้ขาย">{vendorInfo.name}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">{vendorInfo.referenceNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เบอร์โทร">{vendorInfo.phoneNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ที่อยู่"> {getAddressText(vendorInfo.address)}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ชื่อผู้ติดต่อ">{vendorInfo.contactInfo?.name}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เบอร์โทรผู้ติดต่อ">{vendorInfo.contactInfo?.phoneNumber}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={284}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={64} textAlign="right">
                จำนวน
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                ราคาต่อหน่วย
              </PDFTableCell>
              <PDFTableCell head width={83} textAlign="right">
                จำนวนเงิน
              </PDFTableCell>
            </PDFTableRow>

            {products.map((product: PurchaseOrderPDFInfo_Product, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCellView width={284} flexDirection="column">
                  <Text>{product.code}</Text>
                  <Text>{product.name}</Text>
                </PDFTableCellView>
                <PDFTableCell width={64} textAlign="right">
                  {numeral(product.amount).format('0,0')} {product.unit}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {numeral(product.price).format('0,0.00')}
                </PDFTableCell>
                <PDFTableCell width={83} textAlign="right">
                  {!!product.price ? numeral(product.amount * product.price).format('0,0.00') : '0.00'}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(purchaseOrderPDFInfo.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {purchaseOrderPDFInfo.remark}
                </PDFInfo>
                {!!purchaseOrderPDFInfo.vendorShippingDate && (
                  <>
                    <PDFInfo label="" color="error">{`* กำหนด ส่งสินค้าถึงบริษัท วันที่ ${dateFormat(
                      purchaseOrderPDFInfo.vendorShippingDate ?? new Date(),
                    )}`}</PDFInfo>
                    <PDFInfo label="" color="error">
                      * หากไม่สามารถส่งได้ทันกำหนด ให้ผู้ขายแจ้งกับทางบริษัทฯ ก่อนล่วงหน้า ก่อนการผลิต
                    </PDFInfo>
                  </>
                )}
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((item) => item.value > 0)
                .map((price) => (
                  <PDFInfo key={price.label} label={price.label} direction="row" dataWidth={160}>
                    {numeral(price.value).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView>

          <PDFView bottomLine="darkGrey">
            <PDFRow>
              <PDFColumn columnSpan={6}>
                <PDFInfo label="ที่อยู่สำหรับขนส่ง">
                  {!!customerShippingAddress ? (
                    <Text>
                      {customerShippingAddress.name} {getAddressText(customerShippingAddress)}
                    </Text>
                  ) : (
                    'บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) 519/174-176 ซอยประชาอุทิศ 121 แขวงทุ่งครุ เขตทุ่งครุ กรุงเทพฯ 10140'
                  )}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <Text>ข้อมูลติดต่อสำหรับขนส่ง</Text>
                {!!customerShippingAddress ? (
                  <>
                    <Text>{customerShippingAddress.consigneeName}</Text>
                    <Text>เบอร์โทร {customerShippingAddress.phoneNumber}</Text>
                  </>
                ) : (
                  <>
                    <Text>ฝ่ายจัดซื้อ</Text>
                    <Text>เบอร์โทร 02-114-8050</Text>
                  </>
                )}
              </PDFColumn>
            </PDFRow>
          </PDFView>

          <PDFView bottomLine="darkGrey">
            <PDFRow>
              <Text style={styles.center}>*** กรุณาแนบใบสั่งซื้อทุกครั้งที่มีการส่งของวางบิลหรือรับเช็ค ***</Text>
              <Text style={styles.center}>
                ทางบริษัทฯ มีสิทธิ์จะส่งสินค้าคืนกลับยังผู้ขายหรือยกเลิกการสั่งซื้อ
                ถ้าสินค้าที่ส่งไม่เป็นตามที่กำหนดไว้ในใบสั่งซื้อ
              </Text>
              <Text style={styles.center}>ใบสั่งซื้อจะมีผลเมื่อลายเซ็นต์อนุมัติของผู้มีอำนาจลงนามเท่านั้น</Text>
            </PDFRow>
          </PDFView>

          <View wrap={false}>
            <PDFRow>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้รับใบสั่งซื้อ" date={purchaseOrderPDFInfo.recCreatedAt} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="เจ้าหน้าที่จัดซื้อ" date={purchaseOrderPDFInfo.recCreatedAt} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้ตรวจสอบ" date={purchaseOrderPDFInfo.recCreatedAt} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="ผู้อนุมัติ" />
              </PDFColumn>
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
        {showedImagesPage && (
          <Page size="A4" style={styles.page}>
            <Text style={styles.title} fixed>
              ภาพสินค้าทั้งหมดจากใบสั่งซื้อ
            </Text>

            <PDFRow align="flex-start">
              {products
                .filter((p) => !!p.imageUrl)
                .map((product: PurchaseOrderPDFInfo_Product, index: number) => (
                  <PDFColumn columnSpan={4} key={index}>
                    <Image
                      style={styles.imageProductGallery}
                      src={{
                        uri: product.imageUrl!,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                        body: '',
                      }}
                    />
                    <PDFInfo label={product.code}>
                      <Text>{product.name}</Text>
                    </PDFInfo>
                  </PDFColumn>
                ))}
            </PDFRow>
          </Page>
        )}
      </Document>
    </SoraPDFViewer>
  )
}

export default PurchaseOrderPDFPage
